import React, { useContext, useEffect } from "react";
import { Row, Col, Card, Input } from "antd";
import { TransactionsContext } from "../../context/transactionContext";
import { useParams } from "react-router-dom";
import { getFormattedDateTime, getLocalTimeUTC } from "../../utils";
import { TRANSACTION_CHECKLIST } from "../../constants";
import ImageUploader from "../../components/imageUploader";
const TransactionForm = () => {
  const { dataid } = useParams();
  const { singleTransaction, getSingleTransaction } =
    useContext(TransactionsContext);
  useEffect(() => {
    if (dataid) {
      getSingleTransaction(dataid);
    }
  }, []);

  const formatData = (data) => {
    if(data?.checklist_id === 9 || data?.checklist_id === 10){
        return getLocalTimeUTC(data.item_value);
    }
    return data?.item_value ?? "";
  }

  return (
    <div className="layout-content">
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox cardbody "
            style={{ padding: "20px 40px" }}
          >
            <Row gutter={[24, 0]}>
              <Col xs={24} md={24}>
                <h5 className="form-mttl">Agreement Detail</h5>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Agreement Code</label>
                <Input
                  disabled={dataid}
                  value={singleTransaction?.agreement?.code}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Agreement Amount</label>
                <Input
                  disabled={dataid}
                  value={singleTransaction?.agreement?.amount}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Profit %</label>
                <Input
                  disabled={dataid}
                  value={singleTransaction?.agreement?.profit}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Agreement Status</label>
                <Input
                  style={{ marginBottom: 15 }}
                  disabled={dataid}
                  value={singleTransaction?.agreement?.agreement_status?.name}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Starting Date</label>
                <Input
                  style={{ marginBottom: 15 }}
                  disabled={dataid}
                  value={getFormattedDateTime(
                    singleTransaction?.agreement?.start_date
                  )}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Ending date</label>
                <Input
                  style={{ marginBottom: 15 }}
                  disabled={dataid}
                  value={getFormattedDateTime(
                    singleTransaction?.agreement?.end_date
                  )}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Location</label>
                <Input
                  disabled={dataid}
                  value={singleTransaction?.location?.name}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Principal</label>
                <Input
                  disabled={dataid}
                  value={singleTransaction?.principal?.name}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={24}>
                <h5 className="form-mttl">Transaction</h5>
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Status</label>
                <Input
                  style={{ marginBottom: 15 }}
                  disabled={dataid}
                  value={singleTransaction?.status}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Warehouse User</label>
                <Input
                  style={{ marginBottom: 15 }}
                  disabled={dataid}
                  value={`${
                    singleTransaction?.warehouse_user?.first_name ?? ""
                  } ${singleTransaction?.warehouse_user?.last_name ?? ""}`}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Financer Name</label>
                <Input
                  style={{ marginBottom: 15 }}
                  disabled={dataid}
                  value={`${
                    singleTransaction?.agreement?.financier?.first_name ?? ""
                  } ${
                    singleTransaction?.agreement?.financier?.last_name ?? ""
                  }`}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Created At</label>
                <Input
                  style={{ marginBottom: 15 }}
                  disabled={dataid}
                  value={getFormattedDateTime(singleTransaction?.created_at)}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Total Invoice(s) / DC(s) Amount</label>
                <Input
                  disabled={dataid}
                  value={
                    parseInt(singleTransaction?.total_invoice_amount ?? 0) +
                    parseInt(singleTransaction?.total_dc_amount ?? 0)
                  }
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Murabha Transaction Amount</label>
                <Input
                  disabled={dataid}
                  value={singleTransaction?.profit ?? 0}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Profit</label>
                <Input
                  disabled={dataid}
                  value={parseFloat(
                    singleTransaction?.profit -
                      (parseInt(singleTransaction?.total_invoice_amount ?? 0) +
                        parseInt(singleTransaction?.total_dc_amount ?? 0) -
                        parseInt(singleTransaction?.excess?.shortage_amount ?? 0))
                  ).toFixed(2)}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              <Col xs={24} md={12} lg={6}>
                <label className="form-lbl">Repayment Date</label>
                <Input
                  disabled={dataid}
                  value={getFormattedDateTime(
                    singleTransaction?.repayment_date
                  )}
                  placeholder=""
                  className="form-input"
                />
              </Col>
              {/* INVOICE */}
              <Col xs={24} md={24}>
                <h5 className="form-mttl">Invoice(s)</h5>
              </Col>
              {singleTransaction?.invoices &&
              singleTransaction?.invoices?.length >= 1 ? (
                <>
                  {singleTransaction?.invoices.map((item, index) => (
                    <React.Fragment key={index}>
                      <Col xs={24} md={12} lg={8}>
                        <label className="form-lbl">Invoice Number</label>
                        <Input
                          style={{ marginBottom: 15 }}
                          disabled={dataid}
                          value={item?.code}
                          placeholder=""
                          className="form-input"
                        />
                      </Col>
                      <Col xs={24} md={12} lg={8}>
                        <label className="form-lbl">Net Invoice Amount</label>
                        <Input
                          style={{ marginBottom: 15 }}
                          disabled={dataid}
                          value={item?.actual_amount}
                          placeholder=""
                          className="form-input"
                        />
                      </Col>
                      <Col xs={24} md={12} lg={8}>
                        <label className="form-lbl">
                          Stock Amount for Murabaha
                        </label>
                        <Input
                          style={{ marginBottom: 15 }}
                          disabled={dataid}
                          value={item?.amount}
                          placeholder=""
                          className="form-input"
                        />
                      </Col>
                      {item.images && item.images.length >= 1 && (
                        <Col xs={24} md={24}>
                          <label className="form-lbl">Images</label>
                          <Row>
                            {item.images?.map((d, i) => (
                              <Col xs={12} md={4} key={i}>
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_ENV}${d?.image}`}
                                  target="_blank"
                                >
                                  <ImageUploader
                                    image={`${process.env.REACT_APP_BACKEND_ENV}${d?.image}`}
                                    isOnlyImage
                                  />
                                </a>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      )}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <Col xs={24} md={24}>
                  <h5 className="empty-form-data">Invoice(s) Not Available</h5>
                </Col>
              )}
              {/* DC */}
              <Col xs={24} md={24}>
                <h5 className="form-mttl">Dc(s)</h5>
              </Col>
              {singleTransaction?.dcs && singleTransaction?.dcs?.length >= 1 ? (
                <>
                  {singleTransaction?.dcs.map((item, index) => (
                    <React.Fragment key={index}>
                      <Col xs={24} md={12} lg={8}>
                        <label className="form-lbl">DC Number</label>
                        <Input
                          style={{ marginBottom: 15 }}
                          disabled={dataid}
                          value={item?.code}
                          placeholder=""
                          className="form-input"
                        />
                      </Col>
                      <Col xs={24} md={12} lg={8}>
                        <label className="form-lbl">Net Dc amount</label>
                        <Input
                          style={{ marginBottom: 15 }}
                          disabled={dataid}
                          value={item?.actual_amount}
                          placeholder=""
                          className="form-input"
                        />
                      </Col>
                      <Col xs={24} md={12} lg={8}>
                        <label className="form-lbl">
                          Stock Amount for Murabaha
                        </label>
                        <Input
                          style={{ marginBottom: 15 }}
                          disabled={dataid}
                          value={item?.amount}
                          placeholder=""
                          className="form-input"
                        />
                      </Col>
                      {item.images && item.images.length >= 1 && (
                        <Col xs={24} md={24}>
                          <label className="form-lbl">Images</label>
                          <Row>
                            {item.images?.map((d, i) => (
                              <Col xs={12} md={4} key={i}>
                                <a
                                  href={`${process.env.REACT_APP_BACKEND_ENV}${d?.image}`}
                                  target="_blank"
                                >
                                  <ImageUploader
                                    image={`${process.env.REACT_APP_BACKEND_ENV}${d?.image}`}
                                    isOnlyImage
                                  />
                                </a>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      )}
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <Col xs={24} md={24}>
                  <h5 className="empty-form-data"> DC(s) Not Available</h5>
                </Col>
              )}
              {/* SHORTAGE */}
              <Col xs={24} md={24}>
                <h5 className="form-mttl">Shortage</h5>
              </Col>
              {singleTransaction?.excess &&
              singleTransaction?.excess?.shortage_amount &&
              singleTransaction?.excess?.shortage_quantity ? (
                <>
                  <Col xs={24} md={12} lg={6}>
                    <label className="form-lbl">Quantity</label>
                    <Input
                      style={{ marginBottom: 15 }}
                      disabled={dataid}
                      value={singleTransaction?.excess?.shortage_quantity}
                      placeholder=""
                      className="form-input"
                    />
                  </Col>
                  <Col xs={24} md={12} lg={6}>
                    <label className="form-lbl">Amount</label>
                    <Input
                      style={{ marginBottom: 15 }}
                      disabled={dataid}
                      value={singleTransaction?.excess?.shortage_amount}
                      placeholder=""
                      className="form-input"
                    />
                  </Col>
                </>
              ) : (
                <Col xs={24} md={24}>
                  <h5 className="empty-form-data">Shortage Not Available</h5>
                </Col>
              )}
              {/* CHECKLIST ITEMS */}
              <Col xs={24} md={24}>
                <h5 className="form-mttl">Checklist Items</h5>
              </Col>
              {singleTransaction?.checklist_items &&
              singleTransaction?.checklist_items?.length >= 1 ? (
                <>
                  {singleTransaction?.checklist_items.map((item, index) => {
                    const isImage = TRANSACTION_CHECKLIST.find(
                      (x) => x.name === item?.checklist?.item
                    );
                    const image = isImage
                      ? singleTransaction[isImage.id]
                      : null;
                    return (
                      <Col md={12} key={index}>
                        <Row>
                          <Col xs={24} md={24}>
                            <label className="form-lbl">
                              {item?.checklist?.item}
                            </label>
                          </Col>
                          {image && (
                            <Col xs={24} md={5}>
                              <a
                                href={`${process.env.REACT_APP_BACKEND_ENV}${image}`}
                                target="_blank"
                              >
                                <ImageUploader
                                  incomingStyle={{
                                    width: "100px",
                                    height: "100px",
                                  }}
                                  image={`${process.env.REACT_APP_BACKEND_ENV}${image}`}
                                  isOnlyImage
                                />
                              </a>
                            </Col>
                          )}
                          <Col xs={24} md={image ? 16 : 24}>
                            <Input
                              style={{ marginBottom: 15 }}
                              disabled={dataid}
                              value={formatData(item)}
                              placeholder=""
                              className="form-input"
                            />
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              ) : (
                <Col xs={24} md={24}>
                  <h5 className="empty-form-data">
                    Checklist Items Not Available
                  </h5>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TransactionForm;
